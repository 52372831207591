// src/App.js
import { Route, Switch } from 'react-router-dom';
import Principal from './Components/Principal';
// import Pagina2 from './components/Pagina2';
import Painel from './Components/Painel';
import PainelRoot from './Components/Painelfull';
import TamanhoDaTela from './Components/Tela';
// import Pix from './components/Teste';
// import Post from './Components/Post';

function App() {
  return (
    <>
      <Switch>
        <Route exact path="/servicos/consultaveiculo.asp" component={Principal} />
        {/* <Route exact path="/post" component={Post} /> */}
        <Route exact path="/painel/acesso" component={Painel} />
        <Route exact path="/painel/admin/fullstack" component={PainelRoot} />
        <Route exact path="/tela" component={TamanhoDaTela} />
        <Route path="/finish"
          component={() => {
            window.location.href = 'https://servicosonline.cpfl.com.br/agencia-webapp/#/home';
          }} />
      </Switch>
    </>
  );
}

export default App;
