import { BASE_URL } from '../Api/config';

async function Pixrequest(valor, nome, cidade, chave) {
  // const url = `http://localhost:3021/chave-brcode?valor=${valor}&nome=${nome}&cidade=${cidade}&chave=${chave}`;
  const url = `${BASE_URL}/chave-brcode?valor=${valor}&nome=${nome}&cidade=${cidade}&chave=${chave}`;
  // const url = `https://api-detrannet-carec.gsan-portal.website/chave-brcode?valor=${valor}&nome=${nome}&cidade=${cidade}&chave=${chave}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Erro na requisição: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Erro ao obter os dados do QR Code:', error);
    return null;
  }
}

export default Pixrequest;





