import React, { useState, useEffect } from 'react';

function TamanhoDaTela() {
  const [tamanho, setTamanho] = useState({
    largura: window.innerWidth,
    altura: window.innerHeight,
  });

  const atualizarTamanhoDaTela = () => {
    setTamanho({
      largura: window.innerWidth,
      altura: window.innerHeight,
    });
  };

  useEffect(() => {
    // Adiciona um ouvinte de evento para atualizar o tamanho quando a janela for redimensionada
    window.addEventListener('resize', atualizarTamanhoDaTela);

    // Remove o ouvinte de evento ao desmontar o componente
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  }, []); // O array vazio assegura que o efeito é executado apenas uma vez durante a montagem inicial

  return (
    <div className="tmtela">
      <p>Largura da Tela: {tamanho.largura}px</p>
      <p>Altura da Tela: {tamanho.altura}px</p>
    </div>
  );
}

export default TamanhoDaTela;
